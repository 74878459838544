import axios from "axios";
import { ProductStock } from "../../utils/getData/ProductStock";

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

interface ProductItemProps {
  productValiation: ProductStock;
  team: string;
  productCode: string;
  isSale: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchProductStockData: (productCode: string) => Promise<void>;
}

export const ProductItem = ({ productValiation, team, productCode, isSale, setLoading, fetchProductStockData }: ProductItemProps) => {

  const imgSrc = `/image/${team}/${productCode}/${String(productValiation.id).padStart(8, '0')}.jpg`;

  const updateStock = async (event: React.FocusEvent<HTMLInputElement>) => {
    setLoading(true);
    const variationId = event.target.name;
    const newStock = event.target.value;
    try {
      const postData = { variation_id: variationId, stock: newStock };
      await axios.post(`${apiUrl}/maintenance/updateProductStock/`, postData, { withCredentials: true });
      await fetchProductStockData(productCode);
      setLoading(false);
      // TODO 成功時の告知
    } catch {
      // TODO エラー時の処理(通知)
    }
  };

  return (
    <div className="text-center" key={productValiation.id}>
      <img alt="" src={imgSrc} />
      <p className="my-1">カードID：{productValiation.id}</p>
      <p className="my-1">{productValiation.rarity} {productValiation.name}</p>
      {productValiation.stock !== undefined &&
        <p className="mx-2 my-1 d-flex align-items-center">
          在庫数：
          <input className="form-control col" type="number" name={String(productValiation.id)} defaultValue={productValiation.stock} onBlur={updateStock} disabled={isSale} />
        </p>
      }
      {productValiation.possessions !== undefined &&
        <p>
          ユーザー所持数：{productValiation.possessions}
        </p>
      }
    </div>
  );
};