import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const price = process.env.REACT_APP_TICKET_PRICE;

export const HowToPlayButton: React.FC<{ teamColor?: string, width: string }> = ({ teamColor, width }) => {
  // react accdion
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const color = teamColor !== undefined ? "bg-" + teamColor + "-secondary" : "bg-danger";

  return (
    <>
      <Button variant="secondary" onClick={handleShow} className={`${color} btn w-${width} mx-auto m-2`}>
        遊び方を見る
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>遊び方</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='mx-auto d-block w-100' alt="howtoplay" src={`/image/howtoplay/howtoplay-sm.jpg`} />
          <p>※下記注意事項を必ず、お読みください。 <br />
            ・ショップでご購入の引換券はご利用できません。<br />
            ・発送には、1チケット（{price}円）消費されます。発送をご依頼すると、獲得した全カードが郵送されます。（選択発送不可）<br />
            ・発送手続きの際は、獲得した選手カードの中なら、好きな選手の裏面に好きな画像を設定できます。その際、5桁の認証番号の記載がなければ、ロゴカードが届きます。入力ミスや正常にアップロードされてない場合もロゴカードが届きます。ロゴカードの場合は、日付は設定できません。（発送期間中の日付が印刷されます。）<br />
            ・ 抽選方式は、オンサイトカード機とは異なるシステムでの抽選になります。（提供割合をご確認ください。）<br />
            ・ 提供割合では、在庫の確認も可能です。<br />
            ・ チケットが余った状態で完売になると、発送手続きのみご利用になれます。余ったチケットは、次期シリーズでご利用になれます。<br />
            ・ アルバム内のカード保管期間は、獲得から1年になります。期間内に発送の手続きをお願いいたします。</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
}

export default HowToPlayButton;