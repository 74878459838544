import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const HowToUseTicketsButton: React.FC = () => {
  // react accdion
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  return (
    <>
      <Button variant="warning" onClick={handleShow} className={`bg-warning btn min-w-200 mx-auto m-2 mb-4`}>
        チケットについて
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>遊び方</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {windowWidth >= 500 ?
            <img className='mx-auto d-block w-100' alt="howtoplay" src={`/image/howtoplay/howtoplay.jpg`} />
            :
            <img className='mx-auto d-block w-100' alt="howtoplay" src={`/image/howtoplay/howtoplay-sm.jpg`} />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
}

export default HowToUseTicketsButton;