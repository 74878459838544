
import axios from 'axios';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Delivery } from '../../mainSectionComponents/management/DeliveryManagement';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

type DeliveryUpdateButtonProps = {
  label: string;
  afterStatus: string;
  checkedIds: string[];
  currentDeliveryData: Delivery[];
  setCurrentDeliveryData: React.Dispatch<React.SetStateAction<Delivery[]>>;
  setTargetDeliveryData: React.Dispatch<React.SetStateAction<Delivery[]>>;
  resetCheckBox: () => void
  setError: (value: React.SetStateAction<boolean>) => void
};


export const DeliveryUpdateButton: React.FC<DeliveryUpdateButtonProps> = ({ label, afterStatus, checkedIds, currentDeliveryData, setCurrentDeliveryData, setTargetDeliveryData, resetCheckBox, setError }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const statusList: { [key: string]: string } = { "notDelivered": "未発送", "working": "仕掛り中", "delivered": "発送済み" }
  const updateDeliveryStatus = async () => {
    if (checkedIds.length === 0) {
      alert("チェック済みの項目がありません");
      return;
    }
    try {
      const postData = { deliveryIds: checkedIds, status: afterStatus };
      const response = await axios.post(`${apiUrl}/maintenance/updateDeliveryStatus/`, postData, { withCredentials: true });
      if (response.status === 204) {
        const updateData = (setData: React.Dispatch<React.SetStateAction<Delivery[]>>, sourceData: Delivery[], checkedIds: string[]) => {
          setData(prevData => {
            const updatedData = prevData.concat(sourceData.filter(item => checkedIds.includes(item.id.toString())));
            return updatedData;
          });
        };

        const removeData = (setData: React.Dispatch<React.SetStateAction<Delivery[]>>, checkedIds: string[]) => {
          setData(prevData => {
            const updatedData = prevData.filter(item => !checkedIds.includes(item.id.toString()));
            return updatedData;
          });
        };
        updateData(setTargetDeliveryData, currentDeliveryData, checkedIds);
        removeData(setCurrentDeliveryData, checkedIds);

        resetCheckBox();

        setShow(false);
      } else {
        alert("更新に失敗しました。ページを更新してください");
      }
    } catch (error) {
      alert("更新に失敗しました。ページを更新してください");
      setError(true);
    }
  }

  return (
    <>
      <Button variant="secondary" onClick={handleShow} className={`ms-2`}>
        {label}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>実行確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            {checkedIds.length > 0 ?
              <>
                <div>変更件数：{checkedIds.length}</div>
                <div className='mb-2'>発送依頼を{statusList[afterStatus]}へ変更します。</div>
                <div className='d-flex justify-content-end mb-2'>
                  <Button variant="blue" className="text-white me-3" onClick={(updateDeliveryStatus)}>実行</Button>
                  <Button variant="gray-600" className="text-white" onClick={handleClose}>閉じる</Button>
                </div>
              </> : <>
                <div className='text-danger'>選択項目がありません</div>
                <div className='d-flex justify-content-end'>
                  <Button variant="gray-600" className="text-white" onClick={handleClose}>閉じる</Button>
                </div>
              </>}

          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeliveryUpdateButton;