import React, { useState } from 'react';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import Error500Component from '../error/Error500';
import HowToUseTicketsButton from '../../subComponents/howtoplay/HowToUseTicketsButton';
import LoadingScreen from '../loading/LoadingScreen';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

type TicketFormProps = {
  possessionTickets: number,
  price: number,
  orderId: string,
  setStatus: React.Dispatch<React.SetStateAction<string>>,
  buyTickets: number,
  setBuyTickets: React.Dispatch<React.SetStateAction<number>>,
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>,
  setAccessID: React.Dispatch<React.SetStateAction<string>>,
  setAccessPass: React.Dispatch<React.SetStateAction<string>>,
}

export const TicketForm: React.FC<TicketFormProps> = ({ possessionTickets, price, orderId, setStatus, buyTickets, setBuyTickets, setPaymentMethod, setAccessID, setAccessPass }) => {
  const [errorFlag, setErrorFlag] = useState(false);
  const handleConfirm = async () => {
    setLoading(true);
    try {
      const postData = { number: buyTickets, orderId: orderId };
      const response = await axios.post(`${apiUrl}/entryTranCredit/`, postData, { withCredentials: true });
      if (response.status === 200) {
        setAccessID(String(response.data.data.AccessID));
        setAccessPass(String(response.data.data.AccessPass));
        setStatus("confirm");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setErrorFlag(true);
    } finally {
      setLoading(false);
    }

  };
  const [loading, setLoading] = useState(false);

  if (errorFlag) {
    return <Error500Component />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className='mainSection'>
      <div className="text-center border-bottom mt-3">
        <img className='max-w-200' alt="ticket" src="image/ticket.png" />
        <p className="fs-body mb-3">
          オンサイトカード<br />
          購入・発送用チケット<br />
          1枚：{price}円（税込み）<br />
        </p>
        <HowToUseTicketsButton />
      </div>
      <div className="ticketForm">
        <div className="p-2 border-bottom">
          <div className="flex-between align-items-center">
            <p className="fs-subtitle">購入チケット枚数</p>
            <select className="form-control w-25" name="number" onChange={e => setBuyTickets(Number(e.target.value))}>
              {
                Array.from({ length: 30 }, (_, i) => i + 1).map(value =>
                  <option key={value} value={value}>
                    {value}枚
                  </option>
                )
              }
              <option value="40" >40枚</option>
              <option value="50" >50枚</option>
              <option value="60" >60枚</option>
              <option value="70" >70枚</option>
              <option value="80" >80枚</option>
              <option value="90" >90枚</option>
              <option value="100" >100枚</option>

            </select>
          </div>
          <p className="fs-description mt-2">現在のチケット所持数：　{possessionTickets}枚</p>
        </div>
        <div className="p-2 border-bottom">
          <p className="fs-subtitle">お支払方法</p>
          <p className="fs-description my-1">ご希望のお支払い方法を選択してください。</p>
          <label className="d-flex align-items-center">
            <input className="form-check-input" type="radio" name="payment_method" defaultValue="Creditcard" defaultChecked />
            <div className="ms-2 border border-2 d-flex w-100">
              <h1 className='ms-2'><BsFillCreditCard2FrontFill /></h1>
              <p className="fs-description w-100 m-auto ms-2 border-gray">クレジットカード</p>
            </div>
          </label>
        </div>
        <input className="btn btn-primary min-w-200 mx-auto my-4 px-2 py-1" defaultValue="購入内容を確認する" onClick={handleConfirm} />
      </div>
    </div>
  );
};