import { useEffect, useState } from 'react';
import { Products } from '../../utils/getData/PossesionProducts';
import { FormValues } from './Delivery'
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import { LoadingScreen } from '../loading/LoadingScreen'
import Logout from '../auth/Logout';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

type DeliveryConfirmProps = {
  formValues: FormValues,
  productData: Products | {},
  setStatus: React.Dispatch<React.SetStateAction<string>>,
}

export const DeliveryConfirm: React.FC<DeliveryConfirmProps> = ({ formValues, productData, setStatus }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [loading, setLoading] = useState(false);
  const handleDelivery = () => {
    setStatus("first");
  };

  const registerDelivery = async () => {
    try {
      setLoading(true);
      const postData = formValues;
      await axios.post(`${apiUrl}/createDelivery/`, postData, { withCredentials: true });
      setLoading(false);
      setStatus("complete");
    } catch {
      return <Logout reason="セッションの有効期限が切れました。再度ログインしてください。" />
    };
  }

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <>
      <p className="topTitle">発送確認</p>
      <div className="px-1 mainSection">
        <form className='overflow-auto h-500' onSubmit={registerDelivery}>
          <div>
            {Object.entries(productData).map(([productName, productVariations]) => (
              <div key={productName} className="ms-2 text-center">
                <p className="fs-description my-2">{productName}</p>
                {productVariations.map((product) => (
                  [...Array(product.possession)].map((_, possessionIndex) => (
                    <div className='flex-center my-3' key={`${product.variation_id}-${possessionIndex}`}>
                      <div className='max-w-220 mx-4'>
                        <img className="card-shadow my-1 h-150" alt={`${product.variation_id}`} src={`/image/${product.team_name}/${product.product_id}/${String(product.variation_id).padStart(8, '0')}.jpg`} />
                        <p className="fs-description my-2">
                          {product.variation_name} <br /> {product.rarity_name}
                        </p>
                      </div>
                      <div className='mx-2 max-w-150'>
                        <p className="fs-description mt-4 my-1 mx-auto w-75">備考欄</p>
                        <input
                          className="form-control mx-auto w-75 my-1 bg-cleargray pointer-events-none"
                          readOnly
                          type="text"
                          value={formValues.products[productName][product.variation_id][possessionIndex].note || ''}
                        />
                      </div>
                      <input type="hidden" value={product.variation_id} />
                      <input type="hidden" value={product.variation_name} />
                      <input type="hidden" value={product.rarity_name} />
                    </div>
                  ))
                ))}
              </div>
            ))}
          </div>
          <div className='d-table mx-auto my-2'>
            <p className="fs-description my-1">郵便番号</p>
            <input
              readOnly
              className="form-control w-40 min-w-350 bg-cleargray pointer-events-none"
              id="postcode"
              type="text"
              name="postcode"
              value={formValues.postcode}
            />
            <p className="fs-description my-1">住所</p>
            <textarea
              readOnly
              id="address"
              rows={4}
              className="form-control mb-2 w-80 min-w-350 bg-cleargray pointer-events-none"
              name="address"
              value={formValues.address}
            ></textarea>
            <div className='d-flex mb-3 justify-content-evenly'>
              <Button onClick={handleDelivery} variant="secondry">入力内容を変更する</Button>
              <Button onClick={registerDelivery} variant="primary">発送依頼を確定する</Button>
            </div>
          </div>
        </form>
      </div >

    </>
  );
};