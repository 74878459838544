import axios from 'axios';
import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getCurrentUser } from '../../utils/getData/User';
import AuthContext from '../../utils/auth/AuthContext';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

type PurchaseButtonProps = {
  teamColor: string;
  product_code: string;
  setProductValiationId: React.Dispatch<React.SetStateAction<string>>;
  setRarityName: React.Dispatch<React.SetStateAction<string>>;
  setProductValiationName: React.Dispatch<React.SetStateAction<string>>;
  setRareEffect: React.Dispatch<React.SetStateAction<string>>;
  setTickets: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClicked?: React.Dispatch<React.SetStateAction<boolean>>;
};

interface PlayModalProps {
  show: boolean;
  onHide: () => void;
  purchase: () => Promise<void>;
}

function PlayModal({ show, onHide, purchase }: PlayModalProps) {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          商品の購入について
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center my-2">
          <img className="w-25" alt="ticket" src="/image/ticket.png" />
          <p className="fs-topic fw-bold">× 1</p>
        </div>
        商品の購入にはチケットを1枚使用します。
        <Button variant="primary" onClick={purchase} className="btn btn-secondary w-250 mx-auto my-2 py-1">
          商品購入
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>閉じる</Button>
      </Modal.Footer>
    </Modal>
  );
}

const PurchaseButton: React.FC<PurchaseButtonProps> = ({
  teamColor,
  product_code,
  setProductValiationId,
  setProductValiationName,
  setRarityName,
  setRareEffect,
  setTickets,
  setLoading,
  setIsClicked,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { setAuthErrorFlag } = useContext(AuthContext);
  const purchase = async () => {
    try {
      setLoading(true);
      const postData = { product_code: product_code };
      const response = await axios.post(`${apiUrl}/purchaseProduct/`, postData, { withCredentials: true });
      setProductValiationId(response.data.product.id);
      setProductValiationName(response.data.product.name);
      setRarityName(response.data.product.rarity_name);
      setRareEffect(response.data.product.effect);

      const user = await getCurrentUser();
      setTickets(user.tickets);
    } catch {
      setAuthErrorFlag(true);
    } finally {
      if (setIsClicked) {
        setIsClicked(false);
      };
      setLoading(false);
      setModalShow(false);
    }
  };

  return (
    <>
      <Button className={`bg-${teamColor}-primary btn w-75 mx-auto m-2`} variant="primary" onClick={() => setModalShow(true)}>
        商品購入
      </Button>

      <PlayModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        purchase={purchase}
      />
    </>
  );
};

export default PurchaseButton;
