import Button from 'react-bootstrap/Button';

const LogoutButton: React.FC = () => {
  // const navigate = useNavigate();
  // const logout = async () => {
  //   // TODO 例外処理を実装する
  //   try {
  //     const response = await axios.get(`${apiUrl}/auth/jwt/logout`, { withCredentials: true });
  //   } finally {
  //     navigate('/');
  //     window.location.reload()
  //   }
  // };

  return (
    <>
      <Button variant="primary" href="/logout" className="btn btn-secondary w-250 mx-auto my-2 py-1">
        ログアウト
      </Button>
    </>
  );
};

export default LogoutButton;
