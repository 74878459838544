import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

const Help: React.FC = () => {
  return (
    <>
      <p className="topTitle">よくあるご質問</p>
      <div className='mainSection mb-3'>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>画面の表示が乱れる、画像が表示されない</Accordion.Header>
            <Accordion.Body>
              <div>
                <p className='mb-1'>
                  1.最新版のGoogleChromeを利用してください。<br />
                  【パソコン】
                </p>
                <a href="https://www.google.com/intl/ja_jp/chrome/">Google Chrome</a><br />
                <br />
                <p className='mb-1'>
                  【スマートフォン】<br />
                  ■iPhone をお使いの方<br />
                </p>
                <a href="https://apps.apple.com/jp/app/google-chrome/id535886823" className='d-inline-block'>
                  <img src="/image/help/AppStore_Badge.svg" className='w-250 mb-3'></img>
                </a>
                <p className='mb-1'>■Android をお使いの方</p>
                <a href="https://play.google.com/store/apps/details?id=com.android.chrome&pcampaignid=web_share" className='d-inline-block'>
                  <img src="/image/help/GooglePlay_Badge.png" className='w-250 mb-3'></img>
                </a>
                <p className='mb-1'>2.ブラウザのキャッシュやクッキーを削除してください。</p>
                <a href="https://support.google.com/accounts/answer/32050?hl=ja&co=GENIE.Platform%3DDesktop">キャッシュとCookieの削除方法</a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>商品はいつ届きますか？</Accordion.Header>
            <Accordion.Body>
              <p>
                発送手続き完了から7営業日以内でお届け予定です。<br />
                発送手続きから7営業日以上経過して商品が届かない場合、発送手続き完了のお知らせメールに記載された注文番号を記載のうえ、下記のメールアドレスへご連絡ください。<br />
                info@pbridge.net
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>発送した商品が届かない</Accordion.Header>
            <Accordion.Body>
              <p className='mb-0'>
                1.発送依頼が完了しているかご確認ください。<br />
                ご登録されているメールアドレスへ発送受付完了メールが送付されているかご確認ください。<br />
                発送依頼が完了していない場合は下記のリンクから発送依頼を行うことができます。<br />
              </p>
              <p className='mb-0 text-danger'>※ログインが必要となります。ログインされていない場合、トップページへ遷移します。</p>
              <Link to="/delivery">発送依頼ページ</Link>
              <p className='mt-3'>
                2.発送依頼時お届けしているメールに記載された注文番号を記載のうえ、下記のメールアドレスへご連絡ください。<br />
                info@pbridge.net
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>裏面画像を設定したが、実際の商品に印刷されていなかった</Accordion.Header>
            <Accordion.Body>
              <p className='mb-0'>
                裏面に写真を入れる場合、下記は無効になりますので、ご注意ください。<br />
                ・アップロードした画像の確認画面で無地（白い）の状態<br />
                →正常にアップロードしておりません。下記の原因が考えられますので、ご確認お願いいたします。<br />
                通信の問題（お客様のパケット使用に制限がかかっているなど）<br />
                Ａ）制限のないWi-fiなどにつなげてお試しください。<br />
                写真の大きさ又は容量が大きい<br />
                Ａ）写真のサイズを圧縮してお試しください。<br />
                写真のファイル形式が非対応のもの（一眼レフなどで撮影した写真など）<br />
                Ａ）該当の写真をスマホの画面に表示して、スクショした写真でお試しください。<br />
                スマホのブラウザが古い又は、非対応のもの<br />
                Ａ）他の端末でお試しください。
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div >
    </>
  );
};

export default Help; 